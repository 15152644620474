import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { BUILD_TYPES, IPageMetaData } from '@scbweb-lib/index';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { filter } from 'rxjs';
import { UiService } from '@shared/services/ui.service';

@Injectable({
	providedIn: 'root',
})
export class SeoService {
	constructor(
		private router: Router,
		private titleSrv: Title,
		private metaSrv: Meta,
		private uiSrv: UiService,
		@Inject(DOCUMENT) private document: Document
	) {
		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
			this.setPageMetaData();
		});
	}

	// *********************** SET TITLE AND META TAGS ***********************

	private getRoutePageMetaData(): IPageMetaData {
		let activatedRoute = this.router.routerState.snapshot.root;
		while (activatedRoute.firstChild) activatedRoute = activatedRoute.firstChild;
		const data: IPageMetaData = activatedRoute.data.pageMetaData;
		return data;
	}

	setPageMetaData(routeData?: IPageMetaData | undefined) {
		if (!routeData) {
			routeData = this.getRoutePageMetaData();
		}

		if (routeData?.setByComponent) {
			return;
		}

		let title: string = '';
		if (routeData?.title?.length) {
			title = routeData.addSuffixAppNameToTitle ? `${routeData.title} | ${environment.appName}` : routeData.title;
		} else {
			title = environment.indexPageMetaData.title!;
		}

		const tags: string[] = [...new Set([...(routeData?.tags ?? []), ...(environment.indexPageMetaData.tags ?? [])])];
		const keywords = tags.join(',');
		const data: IPageMetaData = { ...environment.indexPageMetaData, ...routeData, title, tags, keywords };

		this.titleSrv.setTitle(title);
		const metas: MetaDefinition[] = [
			{ name: 'description', content: data.description ?? '' },
			{ name: 'keywords', content: data.keywords ?? '' },
			{ property: 'og:title', content: data.title ?? '' },
			{ property: 'og:description', content: data.description ?? '' },
			{ property: 'og:url', content: data.url ?? '' },
			{ property: 'og:image', content: data.image ?? '' },
			{ property: 'og:image:alt', content: data.image_alt ?? '' },
			{ property: 'og:image:width', content: data.image_width ?? '' },
			{ property: 'og:image:height', content: data.image_height ?? '' },
			{ property: 'og:locale', content: data.locale ?? '' },
			{ property: 'og:locale_alternate', content: data.locale_alternate ?? '' },
			{ property: 'og:type', content: data.type ?? '' },
			{ property: 'og:site_name', content: data.site_name ?? '' },

			{ name: 'twitter:title', content: data.title ?? '' },
			{ name: 'twitter:description', content: data.description ?? '' },
			{ name: 'twitter:image', content: data.image ?? '' },
			{ name: 'twitter:image:alt', content: data.image_alt ?? '' },
			{ name: 'twitter:site', content: data.twitter_site ?? '' },
		];
		if (environment.buildType === BUILD_TYPES.test) {
			metas.push({ name: 'robots', content: 'noindex, nofollow' });
		}

		// console.log({ title }, { metas });
		this.updateOrSetOrRemoveMetaTags(metas);

		const canonicalUrl = data.canonicalUrl ?? this.router.url;
		if (data.canonicalUrl) {
			this.updateCanonical(canonicalUrl);
		}
	}

	private updateOrSetOrRemoveMetaTags(meta: MetaDefinition[]) {
		meta.map((m) => {
			if (m.content === '') {
				const selector = m.name ? `name="${m.name}"` : `property="${m.property}"`;
				this.metaSrv.removeTag(selector);
			}
			const el = this.metaSrv.updateTag(m);
			if (!el) {
				this.metaSrv.addTag(m, true) as HTMLMetaElement;
			}
			return el;
		});
	}

	private updateCanonical(url: string) {
		const canonicalUrl = environment.domain + url;
		const head = this.document.getElementsByTagName('head')[0];
		let element: HTMLLinkElement | null = this.document.querySelector(`link[rel='canonical']`) || null;
		if (element == null) {
			element = this.document.createElement('link') as HTMLLinkElement;
			head.appendChild(element);
		}
		element.setAttribute('rel', 'canonical');
		element.setAttribute('href', canonicalUrl);
	}
}
